.product-list {
  &--disabled {

    & > td {
      color: rgba(64, 64, 65, 0.45);

      & * {
        color: rgba(64, 64, 65, 0.45);
      }
    }

  }

  &--disabled-in-list {

    & > td {
      color: rgba(64, 64, 65, 0.45);
    }

    & *:not(.product-list--link):not(.product-list--icon > *) {
      color: rgba(64, 64, 65, 0.45);
    }

  }
}
@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";