.calendar_header {
  background-color: #f6fdf6;
  border: solid 2px #7bbe68;
  min-height: 70px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  transition: all 0.6s;
  border-radius: 10px;

  &--color {
    color: #626461 !important;
  }

  &-icon {
    &--calendar {
      font-size: 25px;
    }
  }

  &-text {
    &--date {
      font-size: 1.5em;
    }
  }

  &-mode-change {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    flex: 2;

    & .ant-select-selector, .ant-select {
      > span {
        color: @primary-color !important;
      }
    }
  }

  &--indent {
    margin-right: 5px;
  }
}

@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";