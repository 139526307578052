.ant-tour-footer {
  display: none !important;
}

.ant-tour-header {
  padding: 16px 16px 16px !important;
}

.ant-tour {
  border-radius: 7px;
  box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.5);
}

.ant-tour-arrow {
  &:after {
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2) !important;
  }
}

.ant-tour-close {
  z-index: 10;
}

.ant-tour {
  z-index: 9999999 !important;
}
@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";