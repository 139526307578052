.editable-cell {
  position: relative;

}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-cell-value {
  padding: 4px 11px;
  border-radius: 7px;
  border: 1px solid transparent;
}


.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 7px;
}


.editable-row {
  .table-action-cell-hidden {
    opacity: 0;
    transition: opacity 0.5s;
  }

  &:hover .table-action-cell-hidden {
    opacity: 1;
    transition: opacity 0.5s;
  }
}


.table-action-cell-hidden:has(.table-action-delete:focus-within) {
  opacity: 1;
  transition: opacity 0.5s;
}
@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";