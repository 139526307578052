@import '../../../themes/theme.less';

.month-col {
  height: 130px;
  max-width: 14.2% !important;
  border-top: 1px solid black;
  border-left: 1px solid black;



  &:nth-child(7n+7) {
    border-right: 1px solid black;
  }

  &:nth-last-child(-n+7) {
    border-bottom: 1px solid black;
  }

  &-date {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-wrapper-text {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-top: 3px;
      cursor: pointer;

      &--today {
        background-color: @primary-color;
        z-index: 100;

        > span {
          color: @app-color
        }

        &:hover{
          background-color: darken(@primary-color, 10%)
        }
      }

      &:hover:not(&--today) {
        background-color: @secondary-color;
      }
    }
  }

  &-text {
    &--weekday {
      font-size: 1.1em;
      color: darken(@secondary-color, 15%);
    }
  }
}

@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";