@import '../../../themes/theme.less';

.time-line{
  display: flex;
  align-items: center;
  z-index: 100;
  position: absolute;
  width: 100%;

  &-circle{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: @error-color;
    position: relative;
    left: -5px;
  }
  &-divide{
    width: calc(100% + 5px);
    height: 2px;
    background-color: @error-color;
    position: absolute;
    left: -5px;
  }
}
@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";