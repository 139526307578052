.phone-container {
  .form-control {
    height: 32px !important;
    width: 100% !important;
  }
}
.phone-input-component-container {
  position: relative;
}

@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";