.Wizard {
  margin: 20px 0 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  &-message {
    margin: -15px 0 15px 0;
  }

  //display: grid;
  //grid-template-rows: 50px auto 60px;

  &-step {

    &-body {
      background: @component-background;
      margin-top: 10px;
      flex-grow: 1;
      //overflow: auto;
      max-height: calc(100% - 189px);

      padding: 20px;
    }

    &-header {
      position: relative;

      padding: 10px;
      margin: 0 20px;

      .ant-tabs-bar {
        margin: 0;
      }
    }

    &-footer {
      height: 100%;
      width: 100%;
      background-color: @app-color;
      flex-shrink: 0;
      display: flex;
      justify-content: space-between;
      padding: 10px 10px !important;
      position: sticky;
      position: -webkit-sticky;
      bottom: -10px;
      left: 100%;
      z-index: 2410;
      border: none ;
      margin: 0 !important;

      * > .FormFooter_button-wrapper {
        padding: 0;
      }
    }

    &-tabs {
      &--error {
        color: @error-color !important;
      }
    }

    &-actions {
      //display: flex;
      //width: 100%;
      //flex-direction: row-reverse;
      //justify-content: space-between;

    }
  }


}

.WizardForm {
  height: 100%;
}

.Wizard-full-height {
  height: 100%;
}

.Wizard-step-header--clickable {
  cursor: pointer;
}

.Wizard-step-content {
  display: none;

  &--hidden {
    display: block;
  }
}

.Wizard-step-footer-extra {
  width: 100%;
  flex: 1;
}

.Wizard-step-actions {
  flex-grow: 0;
  text-align: right;

  button {
    margin-left: 10px;
  }
}

@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";