.LoginForm {
}

.LoginForm_header {
}

.LoginForm_footer {
  text-align: center;
}

@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";