@import '../../../themes/theme.less';

.month-col {
  &-modal {
    position: absolute;
    width: 0;
    height: fit-content;
    background-color: @app-color;
    z-index: 1000;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding: 7px 0;
    min-width: 150px;

    &-button-close {
      position: absolute !important;
      right: 3px !important;

      &-icon {
        font-size: 15px;
      }
    }

  }
}


@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";