@import '../../../themes/theme.less';

.calendar-event {
  width: 109%;
  height: 100%;
  position: absolute;
  left: -9%;
  padding-left: 9%;

  &--col {
    //box-shadow: 9px 12px 10px rgba(0, 0, 0, 0.5);
    position: relative;
    background-color: @primary-color;
  }

  &-horizontal {
    &--col {
      overflow: hidden;
      cursor: pointer;
      position: static;

      & .ant-popover-inner{
        padding: 0;
      }

      &:hover {
        background-color: @light-disabled-color;
      }
    }

    &-button {
      width: 100%;
      overflow: hidden;

      &--bold {
        width: 100%;
        text-align: left;
        font-weight: 600;
        font-size: 12px;
      }
    }
  }
}

@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";