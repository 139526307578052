.has-error
  > .ant-form-item-children
  > .company-phone-container
  > #user-form_phone {
  border: 1px solid #f5222d;
}

.user-phone {
  width: 100% !important;
}

.user-create-input-birthday{
  width: 100%;
}
@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";