.sale-list-td {
  width: fit-content;
  min-width: 100px;
  background-color: transparent !important;

  &--full {
    width: 100%;
  }

  &--error {

    background-color: rgba(254, 241, 241, 0.55);

    &:hover > .ant-table-cell {
      background-color: rgba(254, 241, 241, 0.55) !important;
    }

  }

  &--warning {

    background-color: rgba(217, 140, 58, 0.1);

    &:hover > .ant-table-cell {
      background-color: rgba(217, 140, 58, 0.05) !important;
    }

  }
}
@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";