.ActionMenu {
  border-bottom: none !important;
  background: transparent;

  &.ant-menu-horizontal {
    line-height: 22px;
  }

  .ant-menu-item {
    padding: 0 5px;
    .ant-btn {
      //padding: 0 2px;
    }
    .anticon {
      margin-right: 0;
    }
    &:hover,
    &.ant-menu-item-active {
      //border-bottom: 2px solid transparent;
    }
  }

  .ant-dropdown-menu-item {
    padding: 2px 12px;
  }
}

@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";