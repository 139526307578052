.company-pictures {
  transform: rotateY(180deg);

  &__front-card {
    position: absolute;
  }

  &__back-card {
    width: 100% !important;
  }

}
@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";