.base-table-list {
  .ant-table-selection-column {
    width: 30px !important;
  }

  &-td {
    white-space: nowrap;
    width: 1%;
  }

  &--title:not(th) a {
    font-size: 20px;
  }

  &-indent {
    padding: 4px !important;
  }

  th&-indent {
    padding: 12px 8px !important;
  }

  &--transparent {
    background-color: transparent !important;
  }

  &--error {
    background-color: rgba(254, 241, 241, 0.8);

  }

  &--warning {

    background-color: rgba(217, 140, 58, 0.1);
  }

  &--hidden {
    opacity: 0;
    transition: opacity 0.5s;

    &:has(&--delete:focus-within) {
      opacity: 1;
      transition: opacity 0.5s;
    }
  }

  &--row:hover &--hidden {
    opacity: 1;
    transition: opacity 0.5s;
  }
}
@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";