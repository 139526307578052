@import '../../../themes/theme.less';

.calendar-col.calendar-hour-col {
  border-left: none;


  &:before {
    position: absolute;
    content: "";
    height: 1px;
    border-bottom: 1px solid @primary-color;
    border-image: linear-gradient(to left, @primary-color 20%, transparent 20%) 10;
    width: 100%;
    bottom: 0;
  }

}

.calendar-hour-col {
  &-time {
    font-size: 25px;
    position: absolute;
    right: 10%;
    bottom: 70px;
  }
}
@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";