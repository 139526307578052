.Without-layout {
  min-height: 100vh;
  background-color: @app-color !important;
}


.Without-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";