.ErrorPage {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  > img {
    width: 430px;
    margin: 0 auto;
  }
}

@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";