@import '../../../themes/theme.less';

.calendar-item-header {
  height: 65px;
  width: 100%;
  padding-left: 10px;

  @media (max-width: 475px) {
    padding-left: 25px;
  }

  &-button-plus {
    height: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    border-image: linear-gradient(to left, #000 20%, transparent 20%) 10;
  }

  &-col-container {
    height: 100%;
  }

  &-col-date {
    height: 100%;
    padding: 0 20px;
    // backgroundColor: red;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-left: 1px solid black;
    border-image: linear-gradient(to top, #000 30%, transparent 30%) 10;
  }

  &-icon {
    font-size: 30px;
  }

  &-date {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;

    &--today{
      background-color: #1cb400;
      > h3 {
        color: @app-color
      }
      &:hover{
        background-color: darken(#1cb400, 10%)
      }
    }

    &:hover:not(&--today) {
      background-color: @secondary-color;
    }

    &-heading{
      margin: 0;
    }
  }
}
@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";