.PageWrapper {
  padding: 12px 24px;
  min-height: 75vh;

  background: @app-color;

  &.PageWrapper--no-padding {
    padding: 0;
  }

  &.PageWrapper--margin {
    margin-bottom: 20px;
  }

  &.PageWrapper--shadow {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.48);
  }
}

.PageWrapper_header {
  padding: 16px 0;

  .ant-page-header-heading-title {
    font-weight: 400;
  }

  .ant-page-header-heading-extra {
    top: 10px;
  }
}



@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";