.signup-field-phone-container {

  &:hover {
    .signup-selected-flag {
      border-color: @primary-color;
    }
  }
}

.signup-field-phone {
  width: 100% !important;
  height: 42px !important;
  border-color: rgb(236, 236, 236) !important;
  transition: all 0.5s !important;


  &:focus, &:hover {
    border-color: @primary-color !important;

    & + .signup-selected-flag {
      border-color: @primary-color !important;
    }
  }

}

.signup-field-phone-search {
  background-color: @app-color;

  &-box {
    width: 95%;
    background-color: transparent;
  }
}

.signup-selected-flag {
  transition: all 0.5s;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-color: rgb(236, 236, 236) !important;

}
@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";