@import '../../../themes/theme.less';

.calendar-col {
  border-left: 1px solid @primary-color;


  &:before {
    position: absolute;
    content: "";
    height: 1px;
    border-bottom: 1px solid @primary-color;
    width: 100%;
    top: 0;
    z-index: 0;
  }

  &--time-limit {
    background: repeating-linear-gradient(-60deg, lighten(@primary-color, 40%) 0, lighten(@primary-color, 40%) 1px, transparent 1px, transparent 5px);
    cursor: not-allowed;

  }

  &--time-limit&--past-date {
    background: repeating-linear-gradient(-60deg, lighten(@primary-color, 40%) 0, lighten(@primary-color, 40%) 1px, transparent 1px, transparent 5px);
  }

  &--past-date {
    background: @disabled-color;
    cursor: not-allowed;

  }

  &--time-limit-today {
    background: repeating-linear-gradient(-60deg, lighten(#fdf2c4, 40%) 0, lighten(#fcead6, 40%) 1px, #fdf2c4 1px, #fcead6 5px);
  }

  &--adding-event&--time-limit {
    &:hover {
      background-color: @light-disabled-color !important;
    }
  }

  &--adding-event&--past-date {
    &:hover {
      background-color: @light-disabled-color !important;
    }
  }


  &--adding-event {
    &:hover {
      background-color: #ecf6eb !important;

    }
  }

}
@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";