.auth-dropdown-alert {
  max-width: 100%;
  padding: 10px !important;
//  margin-bottom: 8px !important;
  color: #fff;
//  z-index: 9999999 !important;

  & {
    .ant-notification-notice-message, .anticon {
      color: #fff !important;
    }
  }

  &--success {
    background: rgba(93, 146, 114, 0.8) !important;
  }

  &--info {
    background: rgba(79, 124, 172, 0.8) !important;
  }

  &--error {
    background: rgba(177, 60, 60, 0.8) !important;
  }

  &--warning {
    background: rgba(217, 140, 58, 0.8) !important;
  }

  .ant-notification-notice-progress {
    background-color: rgba(177, 60, 60, 0.8) !important;
  }
  //.ant-notification-notice-wrapper .ant-notification-notice-progress::-webkit-progress-bar {}
}

.ant-notification {
  //width: 100% !important;
  inset: 0 auto auto 50% !important;
}
@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";