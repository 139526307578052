.Loader {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Loader-full {
height: 100vh;
}

@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";