.order-select-clint-type {
  .ant-select-item-option-content > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.order-form-footer {
  z-index: 2201 !important;
}
@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";