.SideWindow {
  //z-index: 2400 !important;

  .ant-drawer-body {
    padding: 10px;
    height: 100%;

    .ant-form {
      height: 100%;
    }
  }
}

@media (max-width: 576px) {
  .SideWindow {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
}
@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";