@import '../../../themes/theme.less';

.Table {
  min-height: 50vh;

  .Table_row-expanded {
    > .ant-table-cell {
      padding: 0 !important;
    }

  }
}

.Table--responsive {
  .Table_data-item {
  }

  .Table_title-in-cell {
    font-weight: bold;
  }

  .Table_value-in-cell {
  }

  .ant-table table {
    border-collapse: collapse;
  }

  .ant-table-thead {
    display: none;
  }

  .Table_row {
    position: relative;
    border: 1px solid @border-color-base;
    display: flex;
    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;
    border-radius: 5px;

    &.Table_row--with-expandable {
      padding-bottom: 30px;
    }

    > td {
      padding-top: 3px !important;
      padding-bottom: 3px !important;
      display: block;
      border: 0;
      margin: 3px;
    }

    &:hover {
      > td {
        background: none !important;
      }
    }
  }

  .ant-table-tbody > .ant-table-expanded-row {
    position: relative;
    border: 1px solid @border-color-base;
    display: block;
    margin-top: -5px;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    padding-right: 10px;
    border-top-width: 0;
    padding-top: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    > td {
      padding: 0 !important;
      display: block;
      border: 0;
      margin: 0 0 10px 0;
    }
  }

  .ant-table-row-expand-icon-cell {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

}

@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";