.picker {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
  height: auto;

  &-checkbox{
    > * {
      color: #000 !important;
    }
  }

  &-checkbox {
    flex-basis: 35%;
  }
}

.time-piker-checkbox {
  .ant-picker-date-panel {
    display: none !important;
  }
}
@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";