.store-realization-form {
  position: absolute !important;
  bottom: 0 !important;
  width: 100%;
}

.store-sale-realization {
  transition: opacity 0.5s;
  cursor: pointer;

  &:hover {
    background: rgba(64, 64, 65, 0.15);

    & > td {
      background: rgba(64, 64, 65, 0.15) !important;
    }

  }

  &-button {
    &--delete {
      opacity: 0;

      &:has(button[disabled]) {
        opacity: 1;
      }
    }
  }

  &:hover &-button {
    &--delete {
      opacity: 1;
    }
  }
}

.store-sale-realization--col div:has(.store-sale-realization-button--delete:focus-within) {
  .store-sale-realization-button--delete {
    opacity: 1;
    transition: opacity 0.5s;
  }
}
@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";