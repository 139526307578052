.FormFooter {
  border-top: #d9d9d9 solid 1px;
}

.FormFooter_button-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;

  > * {
    margin-left: 10px;
  }
}

@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";