//App-color
@app-color: #f6f8fa; //#F2EEE7
@app-accent: #FFDF6C;
@app-border-color: lighten(@primary-color, 20%);
@primary-color: #404041; // primary color for all components #feba63
@secondary-color: lighten(@primary-color, 50%);
@link-color: #4f7cac; // link color
@info-color: #1890ff;
@success-color: @link-color; // success state color
@warning-color: #faad14; // warning state color
@error-color: #EF4136; // error state color #f5222d
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: rgba(0, 0, 0, 0.65); // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(64, 64, 65, 0.25); // disable state color
@light-disabled-color: rgba(64, 64, 65, 0.1); // disable state color
@green-color: #4eb15b;
@transparent-error: rgba(254, 241, 241, 0.7);

@primary-bg-color: #05b582;
@primary-button-color: @primary-bg-color;
@primary-button-active-color: #25c290;
@primary-button-border-color: #279c7b;

// Custom
@bottom-popup-background: #464646;
@bottom-popup-background-media: #6a6a6a;
@bottom-popup-accent: #F15A22;

// Sizes
@font-size-base: 14px; // major text font size
@border-radius-base: 4px; // major border radius
@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); // major shadow for layers
@border-radius-base: 0px;


@zindex-message: 100010;
@zindex-modal: 100011;
@zindex-modal-mask: 100011;
@zindex-notification: 100011;
@zindex-popover: 100031;
@zindex-dropdown: 100050;
@zindex-picker: 100050;
@zindex-popoconfirm: 100060;
@zindex-tooltip: 100070;
@zindex-image: 100070;
@zindex-popover: 900051;


//Input
@border-color-base: lighten(@primary-color, 15%); // major border color #d9d9d9
@input-icon-color: @primary-color;
@input-placeholder-color: lighten(@primary-color, 60%);
@input-bg: transparent;

//Label
@label-required-color: @error-color;

//Layout
@component-background: @app-color; //#D1D4CC;
@disabled-bg: @component-background;

//Sider & Sider -> Menu

@layout-sider-background-light: transparent;
@layout-body-background: transparent;
@menu-bg: transparent;
@layout-header-background: @app-color;
@layout-trigger-background: @app-color;

//Menu
@menu-popup-bg: @primary-color;
@menu-highlight-color: #3e58cf;
@menu-item-active-bg: @light-disabled-color;

// Table

@table-bg: transparent;
@table-header-bg: rgba(@app-color, 50%);
@table-header-color: @primary-color;
@table-border-color: lighten(@primary-color, 20%);
@table-expanded-row-bg: transparent;
@table-row-hover-bg: @light-disabled-color;
@table-selected-row-bg: @light-disabled-color;

//Select
@select-item-selected-color: @app-color;
@select-item-active-bg: @light-disabled-color;


//Media
@desktopLarge: 1680px;
@desktop: 1280px;
@desktopSmall: 1024px;
@tableBig: 960px;
@table: 767px;
@mobile: 320px;

// Buttons

//Primary
@btn-primary-bg: @green-color;
@btn-danger-color: @error-color;

//Default
@btn-default-border: @green-color;

// Disable
@btn-disable-border: @light-disabled-color;

// Switch

@switch-color: #E24411;


html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;
}

body {
  overscroll-behavior: none;
}

.ant-form-item-control {
  line-height: normal;
}

.information-field {
  font-weight: bold;
  color: @primary-color !important;
  border: 0;
  padding-left: 0;
}


.ant-input-group-addon {
  //background-color: @primary-color;
  color: @primary-color;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-box-shadow: 0 0 0 50px @component-background inset;
}

.ant-checkbox-inner {
  background-color: @app-color;
}

.ant-upload.ant-upload-select.ant-upload-select-picture-card {
  background-color: transparent;
}

.toolbar-wrapper {
  padding: 0 24px;
  color: #fff;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;

  .anticon {
    padding: 12px;
    cursor: pointer;

    &:hover {
      opacity: 0.3;
    }
  }
}

//Phone input
.field-phone {
  width: 100% !important;
  height: 32px !important;
  background-color: transparent !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 6px !important;
  transition: all 0.5s !important;

  &:focus:not(&[disabled]), &:hover:not(&[disabled]) {
    border-color: #4f7cac !important;

    & + .field-phone-flag {
      border-color: #4f7cac !important;
    }
  }

  &::placeholder {
    //color: rgba(0, 0, 0, 0.88)
  }

  &[disabled] {
    color: rgba(0, 0, 0, 0.25);
  }
}

.field-phone[disabled], .field-phone[disabled] + .field-phone-flag > .selected-flag {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.field-phone-search {
  background-color: @app-color !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 0px !important;

  &-box {
    width: 95%;
    background-color: transparent;
  }
}

//.ant-menu-submenu {
//  z-index: 9999999999 !important;
//}
.ant-menu-inline-collapsed-tooltip {
  display: none;
}


.selected-flag {
  background-color: @app-color !important;
}

.field-flag-dropdown {
  border-color: @primary-color !important;
  border-radius: 0 !important;
}

.country-list {
  background-color: @app-color !important;

  &:hover {
    background-color: @app-color !important;

    > span {
      color: @primary-color !important;
    }
  }
}

.field-phone-flag {
  transition: all 0.5s;
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  border-color: #d9d9d9 !important;
}

.ant-input, .ant-picker-input input, .ant-input-number-input-wrap input, .ant-input-number-input {
  &::placeholder {
    color: @input-placeholder-color !important;
    opacity: 1; /* Firefox */
  }

  &[disabled] {
    &::placeholder {
      //color: rgba(0, 0, 0, 0.25);
    }
  }
}

//.ant-picker-time-panel {
//  display: none;
//}

.ant-segmented-item {
  flex: 1 !important;
}

.ant-dropdown {
  z-index: 557;
}

.ant-layout .ant-layout-sider,
.ant-layout-sider {
  background: rgb(58, 22, 34);
  background-color: rgb(58, 22, 34);
}

.ant-btn-primary {
  background-color: @primary-bg-color;
  &:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: @primary-button-active-color;
    border: 1px solid @primary-button-border-color;
  }
}

.ant-segmented-item-disabled {
  opacity: 0.7;

  &.ant-segmented-item-selected {
    &:hover {
      color: @app-color !important;
    }
  }

}

.prevent-transform-drawer {
  transform: translateX(0) !important;
}

// TABLE
.ant-table-cell {
  vertical-align: top;
}

th.ant-table-cell {
  text-align: center !important;
}






