.image-cash-box{
  position: relative;
  display: inline-flex;
  top: 4px;
  margin-right: 5px;

  &--big {
    margin-left: 5px;
  }
}
@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";