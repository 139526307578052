@import '../../../themes/theme.less';

.bottom-popup {

  &-message {
    display: flex;
    visibility: visible;
    position: fixed;
    bottom: 0;
    right: 20px;
    width: fit-content;
    height: fit-content;
    z-index: 1000;
    max-width: 450px;
    flex-direction: column;

    @media (max-width: 475px) {
      right: 0;
      margin: 10px;
    }

    &__content {
      display: flex;
      align-self: flex-end;
      position: relative;
      min-width: 300px;
      max-width: 450px;
      height: auto;
      padding: 10px 20px;
      border-radius: 5px;
      flex-direction: column;
      align-items: center;
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
      font-size: 1.5rem;
      margin-bottom: 20px;
      background: @bottom-popup-background;

      @media (max-width: 475px) {
        height: 275px !important;
        background-color: @bottom-popup-background-media
      }

      &-animation {
        animation-name: animateCookieModal;
        animation-duration: 1.5s;
      }

      &-animation--reverse {
        animation-name: animateCookieModalReverse;
        animation-duration: 1.5s;
      }
    }

    &__title {
      font-style: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: @error-color;
      font-weight: 600;
      padding: 0 10px 0 0;
    }

    &__text {
      font-style: normal;
      font-size: 13px;
      line-height: 18px;
      color: @app-color;

      & a {
        color: @bottom-popup-accent;
      }
    }

    &__button {
      &--close {
        position: absolute;
        top: 0;
        right: 7px;
        align-self: flex-end;
        color: @app-color;

        &:hover {
          cursor: pointer;
          color: @bottom-popup-accent;
        }
      }
    }
  }
}


@keyframes animateCookieModal {
  from {
    bottom: -250px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes animateCookieModalReverse {
  from {
    bottom: 0;
    opacity: 1;
  }
  to {
    bottom: -250px;
    opacity: 0;
  }
}
@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";