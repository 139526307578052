.posting-list-td {

  &:hover {
    cursor: pointer;

    & > .ant-table-cell {
      background-color: #e9e9e9 !important;
    }

    &&&.posting-list-td--selected {
      & > .ant-table-cell {
        background-color: #e9e9e9 !important;
      }
    }

    & .posting-list-td--icon {
      visibility: visible;
    }

  }

  &--selected {
    & > .ant-table-cell {
      background-color: #e9e9e9 !important;
    }
  }

  &--loading--current {
    & > .ant-table-cell {
      background-color: rgba(93, 146, 114, 0.2) !important;
    }
  }

  &--loading {
    & > .ant-table-cell {
      background-color: #e9e9e9 !important;
    }
  }

}

td.posting-list-td--indent {
  padding: 0 !important;
}

.posting-list-form {
  &:hover {
    .posting-list-td--selected {
      & > .ant-table-cell {
        background-color: transparent !important;
      }
    }
  }

}

.posting-select-popup {
  max-height: 300px;
  overflow: auto;
  //z-index: 9999;

  &--hidden {
    z-index: -1;
    visibility: hidden;
  }
}
@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";