.leftover-list {
  &--error {

    background-color: rgba(254, 241, 241, 0.55);

    &:hover > .ant-table-cell {
      background-color: rgba(254, 241, 241, 0.55) !important;
    }

  }

  &--added {

    background-color: rgba(93, 146, 114, 0.1);

    &:hover > .ant-table-cell {
      background-color: rgba(93, 146, 114, 0.05) !important;
    }

  }
}
@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";