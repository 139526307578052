.app-menu {
  &__company {
    font-weight: 600;
  }

  &--popup {
    z-index: 99999999 !important;
  }
}



@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";