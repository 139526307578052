.client-subscription {
  &-start-date {
    width: 100%;
  }

  &-packages {
    width: 100%;
  }

  &-total {
    width: 100%;
  }
}
@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";