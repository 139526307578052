.realization-list-modal {
  &--selected {

    background-color: rgba(93, 146, 114, 0.1);

    &:hover > .ant-table-cell {
      background-color: rgba(93, 146, 114, 0.05) !important;
    }

  }

  &--transparent {
    background-color: transparent !important;
  }
}
@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";