.parameters-editable-row {
  background-color: rgb(228, 228, 228);
  border-radius: 10px;

  & > td:first-child {
    border-right: none !important;
  }

  &.editable-row.ant-table-row-selected {
    & > td {
      background-color: @app-color !important
    }

  }

  & .ant-table-cell-fix-right {
    background-color: rgb(228, 228, 228);
  }
}
@hack: true; @import "/Volumes/Work/Projects/servicity/sportix-ui/src/themes/theme.less";